import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link, Navigate } from 'react-router-dom';
import PageTitle from '../../components/PageTitle';
import { connect } from 'react-redux';
import { setAlert } from '../../redux/actions/alert';
import { schoolDistrictLookup } from '../../redux/actions/auth';
import Alert from '../Layout/Alert';
import Topbar from '../Layout/topbar/Topbar';

const RegisterAccount = (props: any) => {

    const { auth, setAlert, schoolDistrictLookup } = props;

    const [licenseCode, setLicenseCode] = useState('');

    const licenseOK = auth && auth.district && auth.district.code && auth.district.code.length >= 7;


    const checkLicenseCode = async () => {
        const result = await schoolDistrictLookup( licenseCode );
    };

    return ( licenseOK ? <Navigate to={'register/code'} /> :
        <>
            <Topbar />
            <Container className={'acco-register'}>
                <Row>
                    <Col md={12}>
                        <PageTitle title='Create An Account'
                                   subWidth='full'
                                   subtitle={'Accomods by Action Driven Education, Specially Designed Instruction made simple!  Our unique system contains hundreds of Accomods, detailed descriptions of accommodations and modifications designed to empower students past their learning and behavioral needs, used by IEP teams to develop a meaningful education for students with special needs. '}/>

                        <h1 className="plan-select">Select Your Plan</h1>

                        <div className="acco-plans">
                            <div className="acco-plan">
                                <h4>Parents &<br/>Teachers</h4>
                                <p>Buy a Single License</p>
                                <div>
                                    <ul>
                                        <li><span><b>$39.95 per year</b></span></li>
                                        <li><span>Pay Online</span></li>
                                        <li><span>Instant Access</span></li>
                                        <li><span>Full Accomods Access</span></li>
                                        <li><span>Share Plans with Faculty and Family</span></li>
                                    </ul>
                                </div>
                                {/*<button className='btn btn-danger btn-pill'>Coming Soon</button>*/}
                                <Link to="/register/individual" className="btn btn-primary btn-pill">Register Now</Link>
                            </div>

                            <div className="acco-plan">
                                <h4>Schools &<br/>School Districts</h4>
                                <p>Needs-based licensing</p>
                                <div>
                                    <ul>
                                        <li><span>Needs-Based Pricing</span></li>
                                        <li><span>Unlimited Accounts</span></li>
                                        <li><span>Unlimited Support</span></li>
                                        <li><span>Full Accomods Access</span></li>
                                        <li><span>Share Plans with Faculty and Family</span></li>
                                    </ul>
                                </div>
                                <Link to="/register/district" className="btn btn-primary btn-pill">Get an
                                    Estimate</Link>
                            </div>

                            <div className="acco-plan">
                                <h4>Have an<br/>Access Code?</h4>
                                <p>Register with Existing Plan</p>
                                <div className="access-code">
                                    <b>Already have a school district access code?</b>
                                    <i>Enter it here:</i>
                                    <Alert alertKey="codeError"/>
                                    <input type="text" className="form-control" placeholder="ABCD1234"
                                           maxLength={8}
                                           onChange={(e: any) => setLicenseCode(e.target.value)}
                                           onBlur={() => checkLicenseCode()}
                                    />
                                </div>
                                <button className="btn btn-primary btn-pill"
                                        onClick={() => checkLicenseCode()}>Continue
                                </button>
                            </div>

                        </div>

                        <div className="have-an-account">
                            Already have an Account?
                            <br/><Link to="/">Click here to sign in.</Link>

                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};


const mapStateToProps = (state: any) => ({
    auth: state.auth
});

export default connect(mapStateToProps, { setAlert, schoolDistrictLookup })(RegisterAccount);

